require('./chameleon/images/backgrounds/bg-18.jpg');
require('./chameleon/images/portrait/small/avatar-s-19.png');
require('./chameleon/images/backgrounds/04.jpg');

//favicon
require('./favicon/apple-icon-57x57.png');
require('./favicon/apple-icon-60x60.png');
require('./favicon/apple-icon-72x72.png');
require('./favicon/apple-icon-76x76.png');
require('./favicon/apple-icon-114x114.png');
require('./favicon/apple-icon-120x120.png');
require('./favicon/apple-icon-144x144.png');
require('./favicon/apple-icon-152x152.png');
require('./favicon/apple-icon-180x180.png');
require('./favicon/android-icon-192x192.png');
require('./favicon/favicon-32x32.png');
require('./favicon/favicon-96x96.png');
require('./favicon/favicon-16x16.png');
require('./favicon/ms-icon-144x144.png');
require('./logo_cardiosafe.png');